<script lang="ts" setup>
  import type { Entry } from 'contentful'
  import type { TypePageSkeleton } from '@/types/contentful'

  const slug = useRoute().params.slug as string[]
  const slugString: string = slug.join('/').toLowerCase()

  const { data, error } = await useFetch<Entry<TypePageSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string>>(
    `/api/cms/page/${slugString}`
  )

  if (!data.value || error.value) {
    throw createError({
      statusCode: 404,
      statusMessage: `Route at '${slugString}' not found`,
      fatal: true
    })
  }

  if (data.value.fields.redirect) {
    navigateTo(data.value.fields.redirect, {
      replace: true,
      redirectCode: 301
    })
  }

  useGrilldServerSeoMeta(data.value?.fields.seo)
  useGrilldSeoHead({
    pageTitle: data.value?.fields.seo?.fields.metaTitle ?? undefined,
    canonicalUrl: data.value?.fields.seo?.fields.canonicalUrl ?? undefined,
    jsonLinkingData: data.value?.fields.seo?.fields.jsonLinkingData ?? undefined
  })

  onMounted(() => {
    if (slugString === 'oscar-piastri') {
      // Landing page visit pixel
      const { trackLandingPage } = useAzira()
      trackLandingPage()
    }
  })
</script>

<template>
  <MPageModules v-if="data" :page-data="data" />
</template>
